import styled from "styled-components"
import Logo from "components/Logo"

const Wrapper = styled.div`
  height: 280px;
  position: relative;
  border-bottom: 2px solid #000;
  margin-bottom: 64px;

  ${props => props.theme.media.sm} {
    height: 324px;
  }
`

const Image = styled.div`
  background-size: cover;
  background-position: center;
  height: 110%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`

const HomeLogo = styled.img`
  height: 96px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${props => props.theme.media.sm} {
    height: 128px;
  }
`

const HomeTitle = styled.h1`
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 2;
  line-height: 1;
  margin: 0;
  padding: 10px 24px 14px 24px;
  background-color: ${props => props.theme.color.primaryLight};
  border: 2px solid #000;
  font-weight: normal;
  font-size: 20px;
  white-space: nowrap;

  ${props => props.theme.media.sm} {
    font-size: 30px;
  }
`

const Styled = {
  Wrapper,
  Image,
  HomeLogo,
  HomeTitle,
}

export default Styled
