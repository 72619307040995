import React from "react"
import Styled from "./ActivePoints.styled"

type ActivePointsProps = {
  points: string[]
}

const ActivePoints = ({ points }: ActivePointsProps) => {
  return (
    <Styled.Points>
      {points.map(value => (
        <Styled.Point key={value}>
          <Styled.PointDecorator />
          {value}
        </Styled.Point>
      ))}
    </Styled.Points>
  )
}

export default ActivePoints
