import styled from "styled-components"
import { rgba } from "polished"

const Points = styled.ul`
  list-style-type: none;
  padding-left: 42px;
  font-size: 18px;
`

const PointDecorator = styled.div`
  position: absolute;
  left: -32px;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;

  &::after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #000;
    border-radius: 50%;
    z-index: 5;
  }

  &::before {
    position: absolute;
    content: "";
    left: 2px;
    right: 2px;
    top: -38px;
    height: 38px;
    background-color: #000;
  }
`

const Point = styled.li`
  margin: 16px 0;
  position: relative;

  &:first-child {
    ${PointDecorator} {
      &::before {
        position: absolute;
        content: "";
        left: -6px;
        right: -6px;
        bottom: -6px;
        top: -6px;
        height: auto;
        background-color: #fff;
        border: 2px solid #000;
        border-radius: 50%;
        z-index: -10;
        box-shadow: 0 0 0 8px ${props => props.theme.color.primaryLight};
        animation: pulseAnimation;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }
    }
  }
  &:nth-child(2) {
    ${PointDecorator} {
      &::before {
        height: 32px;
        top: -32px;
      }
    }
  }

  @keyframes pulseAnimation {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${props => rgba(props.theme.color.primaryLight, 1)};
    }

    40% {
      transform: scale(1);
      box-shadow: 0 0 0 7px ${props => rgba(props.theme.color.primaryLight, 1)};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px ${props => rgba(props.theme.color.primaryLight, 0)};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${props => rgba(props.theme.color.primaryLight, 0)};
    }
  }
`

const Styled = {
  Points,
  Point,
  PointDecorator,
}

export default Styled
