import React from "react"
import Styled from "./HomeHeading.styled"
import homeLogo from "assets/images/logo-home.svg"

type HomeHeadingProps = {
  backgroundImage?: string
  subtitle?: string
}

const HomeHeading = ({ backgroundImage, subtitle }: HomeHeadingProps) => (
  <Styled.Wrapper>
    <Styled.Image style={{ backgroundImage: `url(${backgroundImage})` }} />
    <Styled.HomeLogo src={homeLogo} />
    {subtitle && <Styled.HomeTitle>{subtitle}</Styled.HomeTitle>}
  </Styled.Wrapper>
)

export default HomeHeading
