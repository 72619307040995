import styled from "styled-components"

type ColumnProps = {
  xs?: number
  sm?: number
  md?: number
  lg?: number
}

const Column = styled.div<ColumnProps>`
  width: ${props => ((props.xs || 12) * 99.99) / 12}%;
  padding: 0 16px;

  ${props => props.theme.media.sm} {
    width: ${props => ((props.sm || props.xs || 12) * 99.99) / 12}%;
  }

  ${props => props.theme.media.md} {
    width: ${props => ((props.md || props.sm || props.xs || 12) * 99.99) / 12}%;
  }

  ${props => props.theme.media.lg} {
    width: ${props =>
      ((props.lg || props.md || props.sm || props.xs || 12) * 99.99) / 12}%;
  }
`

export default Column
