import React, { useEffect } from "react"
import HomeLayout from "../layouts/HomeLayout"
import HomeHeading from "components/HomeHeading/HomeHeading"
import HomeContainer from "components/HomeContainer"
import Row from "components/Row"
import SectionTitle from "components/SectionTitle"
import WIcon from "components/WIcon"
import * as icons from "ionicons/icons"
import Button from "components/Button"
import Column from "components/Column"
import ActivePoints from "components/ActivePoints"
import { graphql, Link, useStaticQuery } from "gatsby"
import { parseDateForGql } from "tools/parseDateForGql"
import { gql, useQuery } from "@apollo/client"
import config from "config/config"
import { useGuestApolloClient } from "services/client"
import { parseStringDate } from "tools/parseStringDate"
import Widget from "components/Widget"
import WidgetSection from "components/Widget/WidgetSection"
import Heading from "components/Heading"
import ResponsiveMap from "components/ResponsiveMap"
import AudioPlayer from "components/AudioPlayer/AudioPlayer"
import ResponsiveEmbed from "components/ResponsiveEmbed"
import { getYoutubeIdFromUrl } from "tools/getYoutubeIdFromUrl"
import styled from "styled-components"
import loadable from "@loadable/component"
import { Helmet } from "react-helmet"

const Gallery = loadable(() => import("components/Gallery/Gallery"))
const Presenter = loadable(() => import("components/Presenter"))

const useHomePageQuery = () => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const apolloClient = useGuestApolloClient()

  const staticData = useStaticQuery(graphql`
    query {
      strapi {
        homepage {
          subtitle
          seo_description
          seo_image {
            url
          }
          backgroundImage {
            url
          }
          content {
            ... on STRAPI_ComponentComponentsImage {
              __typename
              image {
                url
              }
            }
            ... on STRAPI_ComponentComponentsMap {
              __typename
              map
            }
            ... on STRAPI_ComponentComponentsText {
              __typename
              text
            }
            ... on STRAPI_ComponentComponentsYoutube {
              __typename
              youtube
            }
            ... on STRAPI_ComponentComponentsPresentation {
              __typename
              file {
                url
              }
            }
            ... on STRAPI_ComponentComponentsGallery {
              __typename
              gallery {
                url
                width
                height
              }
            }
            ... on STRAPI_ComponentComponentsAudio {
              __typename
              title
              file {
                url
              }
            }
          }
          homepage_section {
            title
            icon
            description
            image {
              formats
            }
            button_url
          }
        }
      }
    }
  `)

  const { data: dynamicData, error, loading } = useQuery(
    gql`
      query GetMasses($where: JSON) {
        meetings(where: $where) {
          date
          mass_hour
        }
      }
    `,
    {
      variables: {
        where: { date_gt: parseDateForGql(yesterday) },
      },
      client: apolloClient,
      ssr: false,
    }
  )

  return { staticData, dynamicData }
}

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 500px;
  margin: auto;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
`

const StyledSectionImage = styled.img`
  display: block;
  max-width: 100%;
  border-radius: 12px;
  margin: auto;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
`

export default function Home() {
  const { staticData, dynamicData } = useHomePageQuery()
  const {
    subtitle,
    backgroundImage,
    content,
    homepage_section,
    seo_description,
    seo_image,
  } = staticData.strapi.homepage

  return (
    <>
      <Helmet>
        <title>{subtitle} | Wspólnota 120</title>
        <meta name={"og:title"} content={`${subtitle} | Wspólnota 120`} />
        <meta name={"description"} content={seo_description} />
        <meta name={"og:description"} content={seo_description} />
        {seo_image && (
          <meta
            name={"og:image"}
            content={config.uploadPrefix + seo_image.url}
          />
        )}
      </Helmet>
      <HomeLayout>
        <HomeHeading
          subtitle={subtitle}
          backgroundImage={config.uploadPrefix + backgroundImage[0].url}
        />
        {content.map((item, index) => (
          <HomeContainer key={index}>
            {item.__typename === "STRAPI_ComponentComponentsMap" && (
              <>
                <Heading level={2} style={{ marginBottom: "24px" }}>
                  Mapa sympatyków
                </Heading>
                <ResponsiveMap
                  src={
                    "https://www.google.com/maps/d/u/0/embed?mid=1N_o7s_0tlQDaAige9FasuKDtMFTPAj-8"
                  }
                />
              </>
            )}
            {item.__typename === "STRAPI_ComponentComponentsText" && (
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            )}
            {item.__typename === "STRAPI_ComponentComponentsImage" &&
              item.image && (
                <StyledImage
                  src={config.uploadPrefix + item.image.url}
                  alt={""}
                />
              )}
            {item.__typename === "STRAPI_ComponentComponentsAudio" &&
              item.file && (
                <AudioPlayer title={item.title} src={item.file.url} />
              )}
            {item.__typename === "STRAPI_ComponentComponentsYoutube" && (
              <ResponsiveEmbed
                src={`https://www.youtube.com/embed/${getYoutubeIdFromUrl(
                  item.youtube
                )}`}
              />
            )}
            {item.__typename === "STRAPI_ComponentComponentsPresentation" &&
              item.file && <Presenter src={item.file.url} />}
            {item.__typename === "STRAPI_ComponentComponentsGallery" && (
              <Gallery
                images={item.gallery.map(image => ({
                  src: config.uploadPrefix + image.url,
                  width: image.width,
                  height: image.height,
                }))}
              />
            )}
          </HomeContainer>
        ))}
        {homepage_section.map((section, index) => (
          <HomeContainer>
            <Row
              columns
              flexWrap
              alignItems={"flex-start"}
              reverse={index % 2 === 1}
            >
              <Column sm={section.image ? 6 : 12}>
                <SectionTitle style={{ fontSize: "18px" }}>
                  <WIcon icon={icons[section.icon]} /> {section.title}
                </SectionTitle>
                <div
                  style={{ marginBottom: "24px" }}
                  dangerouslySetInnerHTML={{ __html: section.description }}
                />
                <div style={{ marginBottom: "24px" }}>
                  <Button as={Link} to={section.button_url} wide>
                    Dowiedz się więcej
                  </Button>
                </div>
              </Column>
              {section.image && (
                <Column sm={6}>
                  <StyledSectionImage
                    src={config.uploadPrefix + section.image.formats.medium.url}
                  />
                </Column>
              )}
            </Row>
          </HomeContainer>
        ))}
        <HomeContainer>
          <Row columns flexWrap alignItems={"flex-start"}>
            <Column md={6} style={{ marginBottom: "42px" }}>
              <SectionTitle style={{ fontSize: "18px" }}>
                Najbliższe msze święte
              </SectionTitle>
              {dynamicData && (
                <ActivePoints
                  points={dynamicData.meetings.map(
                    meeting =>
                      `${parseStringDate(meeting.date)}${
                        meeting.mass_hour ? ", godz. " + meeting.mass_hour : ""
                      }`
                  )}
                />
              )}
            </Column>
            <Column md={6}>
              <Widget white>
                <WidgetSection>
                  <Heading
                    as={"h2"}
                    level={3}
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                      margin: "12px 0 32px 0",
                      fontWeight: "bold",
                    }}
                  >
                    Chcesz wiedzieć więcej?
                  </Heading>
                  <div style={{ marginTop: "16px" }}>
                    <Button as={Link} to={"/przykladowe-spotkanie"} block>
                      Zobacz przykładowe spotkanie
                    </Button>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <Button as={Link} to={"/sympatycy120"} block gray>
                      Jak otrzymać dostęp do materiałów
                    </Button>
                  </div>
                </WidgetSection>
              </Widget>
            </Column>
          </Row>
        </HomeContainer>
      </HomeLayout>
    </>
  )
}
